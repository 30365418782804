import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes'

export default defineNuxtRouteMiddleware((to) => {
    const config = useRuntimeConfig()
    const allowAddressRedirection = Boolean(config.public.intersportAddressesLink)

    if (allowAddressRedirection && to.path === NuxtLinkNameTypes.ADDRESSES_PAGE) {
        return navigateTo('/')
    }
})
